/* @import "~antd/dist/antd.css"; */
@import "~antd/dist/antd.dark.css";
@import "shared/layout/Utils.css";
@import "./dark-theme.scss";
@import "./light-theme.scss";

.global-header {
  position: fixed;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  z-index: 3;
  width: 100%;
}

.side-bar {
  margin-top: 64px;
  min-height: 93.5vh;
}
/* .site-layout {
  padding: 0 50px;
  margin-top: 64px;
  margin-left: 10%;
}

.site-layout-background {
  padding: 24px;
  min-height: 380px;
} */

.side-menu {
  height: 100%;
  padding: 20px 24px;
  font-weight: 600;
}

.side-menu > li {
  padding: 0 !important;
}

.side-menu .ant-menu-submenu-title {
  padding: 0 !important;
}

.side-menu > .ant-menu-item .ant-menu-item-icon,
.side-menu > .ant-menu-submenu .ant-menu-submenu-title .ant-menu-item-icon {
  padding-left: 15px;
}

.side-menu .ant-menu.ant-menu-sub {
  padding: 15px 0;
  font-weight: 400;
}

.quote-container {
  color: aliceblue;
  overflow: hidden;
}

.ant-divider-horizontal.ant-divider-with-text {
  color: #e9e9e9d9;
  border-top-color: #ff7b00;
}

.ant-menu.ant-menu:not(.ant-menu-horizontal)
  .ant-menu-sub
  .ant-menu-item-selected {
  background: none;
  color: #fff;
  font-weight: 600;
}

.ant-menu .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu .ant-menu-item-selected .anticon,
.ant-menu .ant-menu-item-selected .ant-menu-item-icon + span,
.ant-menu .ant-menu-item-selected .anticon + span {
  color: #000;
}

.ant-menu.ant-menu:not(.ant-menu-horizontal) > .ant-menu-submenu-selected {
  background: linear-gradient(278.93deg, #f54e03 -15.33%, #fd9f48 92.09%),
    linear-gradient(47.13deg, #f44a00 1.82%, #fd9f48 122.88%), #252325;
  border-radius: 5px;
  color: #000;
}

.ant-menu .ant-menu-item .ant-menu-item-selected .ant-menu-item-icon + span,
.ant-menu .ant-menu-item .ant-menu-item-selected .anticon + span,
.ant-menu .ant-menu-item .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu .ant-menu-item .ant-menu-item-selected .anticon {
  color: #000 !important;
}

.ant-menu .ant-menu-inline.ant-menu-sub {
  border-radius: 0px 0px 5px 5px;
}

/* .pb-2 {
  padding-bottom: 0.625rem;
}
.pt-2 {
  padding-top: 0.625rem;
}
.pt-3 {
  padding-top: 3rem;
}
.pb-1 {
  padding-bottom: 0.3125rem;
}

.pr-1 {
  padding-right: 0.3125rem;
}
.pl-1 {
  padding-left: 0.6125rem;
}
.ml-1 {
  margin-left: 0.3125rem;
}
.mr-1 {
  margin-right: 0.3125rem;
} */
.clickable-link {
  cursor: pointer;
  color: #ff6600;
}
.clickable-link:hover {
  text-decoration: underline;
  color: #ffe96d;
}

a.ant-typography,
.ant-typography a {
  color: #ff6600;
}
a.ant-typography:hover,
.ant-typography:hover a:hover {
  color: #ffe96d;
}
.ant-tooltip-inner {
  border-radius: 8px;
}

.point-on-hover {
  cursor: pointer;
}

.text-disable.ant-typography {
  cursor: default;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
}

.text-disable {
  cursor: default;
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
}

.text-disable-button {
  -moz-user-select: none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
}

.tournament-status-tag {
  font-size: 2rem;
  font-weight: 100;
  padding: 0.375em;
}

.user-search-multi-select {
  width: 400px;
  margin: auto;
  border-radius: 5px;
  height: 48px;
  border: none;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
}
.balanced-padded-select.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #434343;
}

.user-search-meta-col {
  padding-left: 10px;
  line-height: 1.2;
}

.user-search-meta-col span.ant-typography {
  font-size: 14px;
  font-weight: bold;
  color: #cacaca;
  padding: 0;
  margin: 0;
}

.user-search-meta-col .user-search-meta-name {
  font-size: 11px;
  color: #95daff;
  font-weight: 200;
  padding: 0;
  margin: 0;
}

.user-search-meta-col .user-search-meta-uid {
  font-size: 10px;
  color: #bcbcbc;
  font-weight: bold;
  padding: 0;
  margin: 0;
}

.matched-search-string {
  background-color: #ff6600;
}

.cumulative-radio {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 600px) {
  .stat-card {
    left: -40px;
    width: 300px;
  }
  .stat-title-card {
    margin-top: 30px;
  }
  .granularity-radio-button {
    margin-bottom: 10px;
    justify-content: flex-end;
  }
  .granularity-radio {
    display: flex;
    justify-content: flex-start;
    width: 300px;
    margin-top: 20px;
  }
  .cumulative-radio {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  .category-divider {
    position: relative;
    left: -20px;
  }
  .game-stats-filter-button {
    margin-bottom: 20px;
  }
  .user-search-multi-select {
    width: 150px;
  }

  .ant-menu-inline-collapsed-tooltip {
    display: none;
  }

  .mobile-table-medium {
    transform: scale(0.27);
    transform-origin: top left;
    position: absolute;
    top: 80px;
    display: flex;
    left: 40px;
  }

  .mobile-table-small {
    transform: scale(0.43);
    transform-origin: top left;
    position: absolute;
    top: 80px;
    display: flex;
    left: 40px;
  }

  .mobile-table-large {
    transform: scale(0.19);
    transform-origin: top left;
    position: absolute;
    top: 80px;
    display: flex;
    left: 40px;
  }

  .bottom {
    position: relative;
    transform-origin: top left;
    top: 0px;
    left: -20px;
    max-height: 800px;
  }

  .side-bar {
    z-index: 1;
  }

  .ant-table-filter-dropdown {
    position: fixed;
    transform: scale(0.35);
    transform-origin: top left;
  }

  .ant-tooltip-inner {
    display: None;
  }

  .blitz-chart-div {
    width: 115%;
    position: relative;
    left: -30px;
  }

  @media (max-width: 410px) {
    .mobile-table-medium {
      transform: scale(0.25);
    }

    .mobile-table-small {
      transform: scale(0.41);
    }

    .mobile-table-large {
      transform: scale(0.18);
    }
  }

  @media (max-width: 389px) {
    .mobile-table-medium {
      transform: scale(0.22);
    }

    .mobile-table-small {
      transform: scale(0.37);
    }

    .mobile-table-large {
      transform: scale(0.16);
    }
  }

  .mobile-pagination-medium {
    transform: scale(3);
    width: 620px;
    position: relative;
    top: 40px;
  }

  .mobile-pagination-small {
    transform: scale(2);
    width: 520px;
    position: relative;
    top: 40px;
  }

  .mobile-pagination-large {
    transform: scale(4);
    width: 660px;
    position: relative;
    top: 60px;
  }
}

.range-picker-wrap {
  display: flex;
  justify-content: right;
  margin: 10px 0;
}

.hide-pagination {
  display: none;
}

.ant-form-item-required span {
  display: none;
}

.ant-form-item-required:after {
  display: none;
}

.app-config-input-wrap {
  padding: 0 15px;
  flex-direction: column;
}
.app-config-input-wrap .ant-form-item-label {
  text-align: left;
}

.app-config-input-wrap .ant-input-number {
  width: 100%;
}

.app-config-input-wrap.p-left {
  padding-left: 25px;
}

.add-leaderboard {
  padding-left: 10px;
  cursor: pointer;
}
.add-leaderboard:hover {
  color: #ff7b00;
}
.sign-in-container {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}
.login-form {
  padding: 50px;
  border: 1px solid;
  text-align: center;
  min-width: 420px;
}

.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}

.add-leaderboard.delete svg {
  width: 24px;
  height: 24px;
}

.offers-form .ant-col.ant-form-item-control {
  align-items: flex-end;
}
.offers-form
  .ant-col.ant-form-item-control
  .ant-form-item-control-input-content {
  max-width: 181px;
}

.offers-form .ant-form-item-label label.ant-form-item-required::before {
  content: unset !important;
}

.offer-ends-picker {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
}
.offer-ends-picker .ant-picker {
  width: 181px;
}

.spaced-form .ant-col.ant-form-item-control {
  align-items: flex-end;
}
// .spaced-form .ant-form-item-label label.ant-form-item-required::before {
//   content: unset !important;
// }

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.ant-collapse-header {
  padding: 0;
}

.custom-collapse {
  .ant-collapse-content-box {
    padding: 0;
  }
}

.spaced-form .ant-form-item-control-input {
  width: 300px;
}
.spaced-form .ant-form-item-control-input .ant-input-number-group-wrapper {
  width: 100%;
}

.pd-8 {
  padding: 8px !important;
}

.uploader-wrap {
  display: flex;
  justify-content: center;

  &.offer-image .ant-upload-select-picture-card,
  .ant-upload-list-picture-card-container {
    height: 52px;
    margin: 0;
  }
}

.table-img {
  width: 40px;
}

/* Force update ant style for FloatLabel*/
.ant-input {
  padding: 16px 12px 4px 11px;
}

.ant-input :disabled {
  background: rgb(31, 31, 31);
}

.ant-select .ant-select-selector {
  padding: 16px 10px 4px 11px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 16px 10px 4px 11px;
  height: 48px;
}

.balanced-padded-select.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 4px 10px 4px 11px;
  height: 44px;
}

/* .ant-select-single .ant-select-selector .ant-select-selection-search {
  top: 16px;
} */

/* .m-card {
  margin-left: 10px;
  border: unset;
  background: unset;
} */

.app-config-form .ant-row .ant-col.ant-form-item-label,
.app-config-form .ant-row .ant-col.ant-form-item-control {
  flex: 1;
}
.app-config-form .ant-row .ant-col.ant-form-item-label {
  white-space: break-spaces;
}
.app-config-form .ant-row .ant-col.ant-form-item-control .ant-input {
  padding: 5px;
}

.header {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  padding: 0 24px;
  background: #252325;
}

.header .logo {
  float: left;
  padding-left: 15px;
}

.header .header-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ant-layout {
  height: auto;
  min-height: 100%;
}

.ant-layout-sider {
  overflow: auto;
  position: fixed;
  left: 0;
  top: 64px;
  bottom: 0;
}

.side-menu .ant-menu-submenu {
  margin-bottom: 8px;
}

.side-menu .ant-avatar .anticon {
  font-size: 12px;
}

.content-bg {
  border-radius: 5px;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 5px 5px 0 0;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  background: transparent;
  border: 0;
}

.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  font-size: 14px;
}

.ant-tabs-card.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 15px 30px;
}

.page-wrapper {
  padding: 32px 48px;
}

.text-uppercase {
  text-transform: uppercase;
}

.section {
  margin-bottom: 32px;
}

.section-card {
  margin-bottom: 8px;
  border-radius: 12px;
}

.section .stat-card,
.section .ant-card {
  margin-top: 12px;
}

.mr-8 .ant-card-body {
  margin-right: 0px;
}

.fee-display .ant-card-body {
  padding: 12px;
  width: 76px;
  border-radius: 20px;
}

/* Dashboard */
.topStat .ant-statistic-content-prefix,
.topStat .ant-statistic-content-value {
  font-size: 44px;
}

.btn-brand {
  background: #ff6725;
  color: #000;
  border: none;
  font-weight: 600;
}

.btn-brand:hover {
  background: #ff6725;
  color: #000;
  opacity: 0.9;
  font-weight: 600;
}

.header-toolbar
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 0 12px;
}

.header-toolbar .ant-select-clear {
  background: none;
}

.header-toolbar .ant-select-selection-placeholder {
  display: flex;
  align-items: center;
}

.header-toolbar .ant-select .ant-select-selection-item,
.header-toolbar .ant-select .ant-select-selection-search {
  display: flex;
  align-items: center;
}

.triumph-tab > .ant-tabs-nav,
.triumph-tab div > .ant-tabs-nav {
  margin-bottom: 0px;
}

.ant-form-item.app-config-input-wrap .normal-select .ant-select-selector {
  height: 32px;
  padding: 0 11px;
}

.app-config-container {
  margin: 30px;
}

.spaced-form.apple-team-id .ant-form-item-control-input {
  width: 280px;
  margin-left: 20px;
}

.ant-btn.ant-dropdown-trigger .anticon {
  font-size: 10px;
}

.ant-picker-clear {
  background: #444244;
}

.ant-radio-button-wrapper:first-child {
  border-radius: 5px 0 0 5px;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 5px 5px 0;
}

.ant-radio-button-wrapper {
  color: rgba(255, 255, 255, 0.8);
}

.ant-radio-button-wrapper-disabled {
  color: rgba(255, 255, 255, 0.4);
}

.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled):hover {
  color: #ff6725;
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover::before {
  background: #ff6725;
}
.guidance-box {
  margin-top: 150px;
  padding: 10px;
  margin-left: 20px;
  border-radius: 3px;
  background: #444244;
}

.normal-option-size li.ant-pagination-options .ant-select-selector,
.ant-select.select-normal .ant-select-selector {
  height: 32px;
  padding: 0px 10px;
}

.ant-switch.ant-switch-checked {
  background: #ff6725;
}

.side-menu .ant-menu-item:hover,
.side-menu .ant-menu-item:focus,
.side-menu .ant-menu-item:active,
.side-menu .ant-menu-submenu-title:focus,
.side-menu .ant-menu-submenu-title:active {
  background: none;
}

.logo {
  width: 232px;
  height: 100%;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left,
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: none;
}

.ant-menu-horizontal {
  border: none;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border: none;
}
.overlay-whole-screen {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(146, 134, 134, 0.4);
}
.loader-overlay {
  top: 50%;
  position: absolute;
  right: 45%;
}
.loader-overlay span svg {
  width: 5em;
  height: 5em;
  fill: #fd9f48;
}
.ant-select.large-select-center-aligned div.ant-select-selector {
  padding: 7px 10px;
  border: 1px solid #434343;
}

.merchant-id-step .ant-steps-item-description {
  overflow-wrap: break-word;
  min-width: 165px;
}

a.ant-typography.disabled-link {
  cursor: unset;
  color: unset;
}

.ant-table-wrapper {
  margin-bottom: 10px;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid #fd9f48;
}

.ant-avatar {
  width: 20px;
  height: 20px;
  line-height: 20px;
}

.ant-modal-confirm.disabled-confirm
  .ant-modal-confirm-btns
  button.ant-btn-primary {
  display: none;
}

.primary-color-picker input {
  color: black;
}

.schedule-pn-container {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-top: 10px;
}

.scehdule-pn-icons {
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 10px;

  span.anticon {
    font-size: 28px;
  }
}

.schedule-pn-container .schedule-input {
  margin: 10px;
  height: 50px;
  padding: 0;
  input {
    height: 40px;
  }
  textarea {
    height: 50px;
  }
  &.scheduledIn {
    input {
      height: 47px;
    }
  }
}

.add-notif {
  margin: 0;
  padding: 0;
  span.anticon {
    font-size: 24px;
  }
}
.input-p-10 {
  padding: 10px;
}

.offer-editable-field {
  height: 52px;
  input.offer-editable-field,
  textarea {
    height: 100%;
    width: 180px;
    padding: 4px 11px;
  }
  input.ant-input-number-input {
    height: 50px;
  }
}

.configure-offer-icons {
  display: flex;
  margin-top: 2px;
  button {
    width: 10px;
    height: 10px;
    padding: 0;
    margin: 10px;

    span.anticon {
      font-size: 18px;
    }
  }
}

.preview-ios-text {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

.preview-offer-wrap {
  display: flex;
  justify-content: center;
  .preview {
    width: 240px;
    height: 400px;
    // border: 1px solid;
    border-radius: 15px;
    position: relative;
    .close {
      position: absolute;
      right: 10px;
      top: 10px;
      // background: black;
      // opacity: 20%;
      width: 30px;
      border-radius: 20px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .p-wrap {
      padding: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      h5 {
        font-size: 12px;
        font-weight: 400;
      }
      h4,
      h5 {
        text-align: center;
      }
      .heading {
        height: 200px;
        display: flex;
        justify-content: center;
      }
      button {
        width: 100%;
        margin-top: 6px;
      }
      .amount-wrap {
        width: 50%;
        display: flex;
        margin-top: 40px;
        margin-bottom: 4px;
        div {
          flex: 1;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-top: 20px;
        }
      }
      .amount-box {
        background-color: rgba(36, 34, 34, 0.3);
        border-radius: 4px;
        font-family: "-apple-system";
        .cash-label {
          font-size: 12px;
          font-weight: 600;
          margin-bottom: 4px;
        }
      }
    }
  }
}

.preview-banner-wrap {
  display: flex;
  justify-content: center;
  .preview {
    width: 240px;
    height: 300px;
    border-radius: 15px;
    position: relative;
  }
}

.banner-container {
  width: 329.18px;
  height: 136.6px;
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  border-radius: 18.0242px;
}

.banner-title-container {
  margin-top: 14px;
}

.banner-title {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.38px;
  color: #ffffff;
}

.banner-subtitle-container {
  // width: 50%;
  margin-top: 4px;
  overflow-wrap: break-word;
}

.banner-subtitle {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11.3837px;
  line-height: 14px;
  margin: 0 auto;
  max-width: 300px;
  text-align: center;
  letter-spacing: 0.288387px;
  color: #8d8791;
}

.banner-button-body {
  position: absolute;
  left: 4.9%;
  right: 4.9%;
  top: 57.64%;
  bottom: 15.28%;
  align-content: center;
  background: #123f7c;
  border-radius: 12.3323px;
}

.banner-button-icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.banner-button-text {
  // width: 120px;
  // height: 17px;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14.3055px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.271804px;

  color: #ffffff;
  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.wrap-editable-view {
  overflow-x: auto;
}

.wrap-editable-view .wrap-inner {
  width: 1800px;
}

.offer-editable-field.expiration-editable .ant-row.ant-form-item-row,
.offer-editable-field.duration-editable .ant-row.ant-form-item-row {
  position: relative;
}

.offer-editable-field.expiration-editable .ant-col.ant-form-item-label,
.offer-editable-field.duration-editable .ant-col.ant-form-item-label {
  position: absolute;
  top: -25px;
}

.offer-editable-field.expiration-editable .ant-col.ant-form-item-label label,
.offer-editable-field.duration-editable .ant-col.ant-form-item-label label {
  font-size: 8px;
}

.offer-editable-field.duration-editable.main-label
  .ant-col.ant-form-item-label {
  top: -35px;
}

.user-action-wrap {
  padding: 3px;
}
.user-action-wrap button {
  margin-top: 5px;
}

.app-config-input-wrap.bordered-select .ant-select.ant-select-lg {
  border: 1px solid #434343;
}

.stats-loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.global-search-wrap {
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #404040;
  height: 47px;
  cursor: text;
  color: #404040;
}
.global-search-wrap:hover {
  color: #4d4d4d;
  border-color: #4d4d4d;
}

.user-search-input-v2 input {
  padding: 12px;
  font-size: 18px;
}

.spin-overlay-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-bar-modal-with-spin .ant-modal.body {
  position: relative;
}

.normal-select.filter-payment {
  .ant-select-selector {
    padding: 0;
    border: 1px solid #434343;
    border-radius: 5px;
  }
}

.position-relative {
  position: relative;
}

.position-absolute-select {
  position: absolute;
  top: -25px;
}

.demo-loadmore-list ul.ant-list-items {
  border: 1px solid #252325;
  padding: 10px;
  background: #434343;
}

.hidden-label {
  label[for="app-config_displayName"] {
    display: none;
  }
}
